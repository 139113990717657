import {MC_Constants} from "../MC_Constants";

// This is a log of an action performed within MC
export class MC_Action_Log {

    // Admin logs
    public static TYPE_ADMIN_CREATE_CLIENT_SUMMARY: string = "admin_created_client_summary";
    public static TYPE_ADMIN_CREATE_SENSOR_SUMMARY: string = "admin_created_sensor_summary";
    public static TYPE_ADMIN_CREATE_ALERT_SUMMARY: string = "admin_created_alert_summary";
    public static TYPE_ADMIN_REPORT_SENT: string = "admin_reports_sent";
    // User logs
    public static TYPE_USER_CREATED_IN_ORG: string = "user_created_in_org"; // user created
    public static TYPE_USER_UPDATED: string = "user_updated"; // user updated
    public static TYPE_USER_DELETED: string = "user_deleted"; // user deleted
    public static TYPE_USER_ADDED_TO_ORG: string = "user_added"; // user added to org
    public static TYPE_USER_REMOVED_FROM_ORG: string = "user_removed"; // user removed from org
    // Device logs
    public static TYPE_DEVICE_CREATED: string = "device_created"; // device created
    public static TYPE_DEVICE_UPDATED: string = "device_updated"; // device updated
    public static TYPE_DEVICE_DELETED: string = "device_deleted"; // device deleted
    public static TYPE_DEVICE_ADDED_TO_ORG: string = "device_added"; // device added to org
    public static TYPE_DEVICE_REMOVED_FROM_ORG: string = "device_removed"; // device removed from org
    public static TYPE_DEVICE_ACTION_TRIGGERED: string = "device_action_triggered"; // device action triggered
    // Alert logs
    public static TYPE_ALERT_CREATED: string = "alert_created"; // alert created
    public static TYPE_ALERT_UPDATED: string = "alert_updated"; // alert updated
    public static TYPE_ALERT_DELETED: string = "alert_deleted"; // alert deleted
    public static TYPE_ALERT_TRIGGERED: string = "alert_triggered"; // alert triggered
    // Organization logs
    public static TYPE_ORG_CREATED: string = "org_created"; // org created
    public static TYPE_ORG_UPDATED: string = "org_updated"; // org updated
    public static TYPE_ORG_DELETED: string = "org_deleted"; // org deleted
    public static TYPE_ORG_CLAIMED_DEVICE: string = "org_claimed_device"; // org claimed device
    // Event logs
    public static TYPE_EVENT_CREATED: string = "event_created"; // event created
    public static TYPE_EVENT_UPDATED: string = "event_updated"; // event updated
    public static TYPE_EVENT_DELETED: string = "event_deleted"; // event deleted
    // Issue logs
    public static TYPE_ISSUE_CREATED: string = "issue_created"; // issue created (open initially)
    public static TYPE_ISSUE_UPDATED: string = "issue_updated"; // issue updated
    public static TYPE_ISSUE_ADMIN_ADDED: string = "issue_admin_added"; // admin is working on issue
    public static TYPE_ISSUE_ADMIN_REMOVED: string = "issue_admin_removed"; // admin is NOT working on issue
    public static TYPE_ISSUE_REOPENED: string = "issue_reopened"; // issue reopened (unresolved)
    public static TYPE_ISSUE_CLOSED: string = "issue_closed"; // issue closed (resolved)
    public static TYPE_ISSUE_DELETED: string = "issue_deleted"; // issue deleted (before expected)
    // Billing logs
    // -- jas fees
    public static TYPE_JAS_FEE_COLLECTED: string = "jas_fee_collected"; // JAS fee collected
    public static TYPE_JAS_FEE_REFUNDED: string = "jas_fee_refunded"; // JAS fee refunded
    public static TYPE_JAS_FEE_REFUND_UPDATED: string = "jas_fee_refund_updated"; // JAS fee refund updated
    // -- prepaid year
    public static TYPE_DEVICE_PREPAID_YEAR_CLAIMED: string = "device_prepaid_year_claimed"; // Device prepaid year claimed
    // -- payment method
    public static TYPE_PAYMENT_METHOD_CREATED: string = "payment_method_created";
    public static TYPE_PAYMENT_METHOD_UPDATED: string = "payment_method_updated";
    public static TYPE_PAYMENT_METHOD_REMOVED: string = "payment_method_removed";
    // -- subscription --
    public static TYPE_SUB_CREATED: string = "sub_created"; // subscription created
    public static TYPE_SUB_UPDATED: string = "sub_updated"; // subscription updated
    public static TYPE_SUB_PAUSED: string = "sub_paused"; // subscription paused
    public static TYPE_SUB_RESUMED: string = "sub_resumed"; // subscription resumed
    public static TYPE_SUB_PAYMENT_FAILURE: string = "sub_payment_failure";
    public static TYPE_SUB_PAYMENT_SUCCESS: string = "sub_payment_success";
    public static TYPE_SUB_CANCELED: string = "sub_canceled"; // subscription canceled
    public static TYPE_SUB_DELETED: string = "sub_deleted"; // subscription deleted

    // Field constants
    public static FIELD_ID: string = "id";
    public static FIELD_ADMIN_ONLY: string = "admin_only";
    public static FIELD_TYPE: string = "type";
    public static FIELD_TIMESTAMP: string = "timestamp";
    public static FIELD_DESC: string = "desc";
    public static FIELD_USER_ID: string = "user_id";
    public static FIELD_USER_NAME: string = "user_name";
    public static FIELD_USER_ADMIN: string = "user_admin";
    public static FIELD_USER_IDS: string = "user_ids";
    public static FIELD_DEVICE_IDS: string = "device_ids";
    public static FIELD_ALERT_IDS: string = "alert_ids";
    public static FIELD_ORGANIZATION_IDS: string = "organization_ids";
    public static FIELD_EVENT_IDS: string = "event_ids";
    public static FIELD_ISSUES_IDS: string = "issue_ids";
    public static FIELD_CONFIG: string = "config";

    public id: string;
    public adminOnly: boolean;
    public type: string;
    public timestamp: Date;
    public desc: string;
    public user_id: string;
    public user_name: string;
    public user_admin: boolean;
    public user_ids: string[];
    public device_ids: string[];
    public alert_ids: string[];
    public organization_ids: string[];
    public event_ids: string[];
    public issue_ids: string[];
    public config: MC_Action_Log_Config;

    private constructor(data: any) {
        // Assign data
        this.id = data[MC_Action_Log.FIELD_ID] as string;
        this.adminOnly = MC_Constants.parseNullableField<boolean>(
            data,
            MC_Action_Log.FIELD_ADMIN_ONLY,
            true
        );
        this.type = data[MC_Action_Log.FIELD_TYPE] as string;
        // Parse timestamp
        let ts: Date | null = MC_Constants.parseDate(data[MC_Action_Log.FIELD_TIMESTAMP] as string);
        if (ts == null) {
            throw "Invalid Timestamp";
        }
        this.timestamp = ts;
        this.desc = data[MC_Action_Log.FIELD_DESC] as string;
        this.user_id = data[MC_Action_Log.FIELD_USER_ID] as string;
        this.user_name =  data[MC_Action_Log.FIELD_USER_NAME] as string;
        this.user_admin =  data[MC_Action_Log.FIELD_USER_ADMIN] as boolean;
        this.user_ids = data[MC_Action_Log.FIELD_USER_IDS] as string[];
        this.device_ids = data[MC_Action_Log.FIELD_DEVICE_IDS] as string[];
        this.alert_ids = data[MC_Action_Log.FIELD_ALERT_IDS] as string[];
        this.organization_ids = data[MC_Action_Log.FIELD_ORGANIZATION_IDS] as string[];
        this.event_ids = data[MC_Action_Log.FIELD_EVENT_IDS] as string[];
        this.issue_ids = data[MC_Action_Log.FIELD_ISSUES_IDS] as string[];
        this.config = data[MC_Action_Log.FIELD_CONFIG] as MC_Action_Log_Config;
    }

    // Log any action logs failed to be processed
    public static processLogs(logsArray: any[]): MC_Action_Log[] {
        let foundLogs: MC_Action_Log[] = [];
        if (logsArray != null) {
            for (let i = 0; i < logsArray.length; i++) {
                try {
                    const log: MC_Action_Log = new MC_Action_Log(logsArray[i]);
                    foundLogs.push(log);
                } catch (err) {
                    console.error("Log process err: " + err);
                }
            }
        }
        return foundLogs;
    }

}

// Any data relating to the action performed will fit within this config
// Any data relating to the action performed will fit within this config
export interface MC_Action_Log_Config {
    // User
    target_user_id?: string;
    target_user_name?: string;
    // Device
    target_device_id?: string;
    target_device_name?: string;
    target_device_jas_id?: string;
    // Alert
    target_alert_id?: string;
    target_alert_name?: string;
    // Organization
    target_organization_id?: string;
    target_organization_name?: string;
    // Event
    target_event_id?: string;
    target_event_name?: string;
    // Issue
    target_issue_id?: string;
    target_issue_subject?: string;
}
