import {MC_Constants} from "../MC_Constants";

export class MC_Recent_Alert {

    public static FIELD_ALERT_ID: string = "alert_id";
    public static FIELD_DEVICE_ID: string = "device_id";
    public static FIELD_DESC: string = "description";
    public static FIELD_RECORDED: string = "recorded";
    public static FIELD_RECEIVED: string = "received";
    public static FIELD_ALERT_TYPE: string = "alert_type";
    public static FIELD_TRIGGER_TYPE: string = "trigger_type";
    public static FIELD_NAME: string = "name";
    public static FIELD_EMAIL: string = "email";
    public static FIELD_PHONE: string = "phone";
    public static FIELD_MESSAGE: string = "message";
    public static FIELD_EMAIL_RESULT: string = "email_result";
    public static FIELD_TEXT_RESULT: string = "text_result";
    public static FIELD_CALL_RESULT: string = "call_result";

    // Fields
    public alertID: string;
    public deviceID: string;
    public description: string;
    public recorded: Date;
    public received: Date;
    public alertType: string;
    public triggerType: string;
    public userName: string;
    public userEmail: string;
    public userPhone: string;
    public message: string;
    public didEmail: number;
    public didText: number;
    public didCall: number;
    // Calculated properties
    public result: number = 0; // Used for sorting purposes
    public resultString: string = "N/A"; // Condensed table data display

    public constructor(data: any) {
        this.alertID = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_ALERT_ID, "");
        this.deviceID = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_DEVICE_ID, "");
        this.description = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_DESC, "");
        // Parse dates
        let recVal: number = Date.parse(data[MC_Recent_Alert.FIELD_RECORDED] as string);
        if (isNaN(recVal)) {
            throw "Invalid recorded date";
        }
        this.recorded = new Date(recVal);
        let receivedVal: number = Date.parse(data[MC_Recent_Alert.FIELD_RECEIVED] as string);
        if (isNaN(receivedVal)) {
            receivedVal = 0; // Just override this
        }
        this.received = new Date(receivedVal);
        // Parse other fields
        this.alertType = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_ALERT_TYPE, "");
        this.triggerType = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_TRIGGER_TYPE, "");
        this.userName = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_NAME, "N/A");
        this.userEmail = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_EMAIL, "N/A");
        this.userPhone = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_PHONE, "N/A");
        this.message = MC_Constants.parseNullableField<string>(data, MC_Recent_Alert.FIELD_MESSAGE, "N/A");
        this.didEmail = data[MC_Recent_Alert.FIELD_EMAIL_RESULT] as number;
        if (this.didEmail == null || isNaN(this.didEmail)) {
            this.didEmail = 0;
        }
        this.didText = data[MC_Recent_Alert.FIELD_TEXT_RESULT] as number;
        if (this.didText == null || isNaN(this.didText)) {
            this.didText = 0;
        }
        this.didCall = data[MC_Recent_Alert.FIELD_CALL_RESULT] as number;
        if (this.didCall == null || isNaN(this.didCall)) {
            this.didCall = 0;
        }
        // Calculate properties
        let hasFailure: boolean = (this.didEmail < 0 || this.didText < 0 || this.didCall < 0);
        let hasSuccess: boolean = (this.didEmail > 0 || this.didText > 0 || this.didCall > 0);
        if (hasFailure) {
            this.result = -1;
            this.resultString = "FAILURE";
        } else if (hasSuccess) {
            this.result = 1;
            this.resultString = "SUCCESS";
        } else {
            // This shouldn't happen
            this.result = 0;
            this.resultString = "N/A";
        }
    }

    public static getArrayFromData(dataArray: any[]): MC_Recent_Alert[] {
        let alerts: MC_Recent_Alert[] = [];
        for (let data of dataArray) {
            try {
                alerts.push(new MC_Recent_Alert(data));
            } catch (err: any) {
                console.error("Recent alert parsing error: " + JSON.stringify(err));
            }
        }
        return alerts;
    }

    public wasSuccessful(): boolean {
        return (this.result > 0);
    }

    public getResultStringForValue(val: number): string {
        if (val > 0) {
            return "SUCCESS";
        } else if (val < 0) {
            return "FAILURE";
        }
        return "N/A";
    }

}
