import {MC_Constants} from "../MC_Constants";

export class MC_Device_Manual_License {

    public static FIELD_START: string = "start";
    public static FIELD_MONTHS: string = "months";

    public start: Date;
    public months: number;

    public static fromValues(start: Date, months: number): MC_Device_Manual_License {
        const data: any = {};
        data[MC_Device_Manual_License.FIELD_START] = start.toJSON();
        data[MC_Device_Manual_License.FIELD_MONTHS] = months;
        return new MC_Device_Manual_License(data);
    }

    public constructor(data: any) {
        const startDate: Date | null = MC_Constants.parseDate(data[MC_Device_Manual_License.FIELD_START]);
        this.start = (startDate != null) ? startDate : new Date(0);
        this.months = MC_Constants.parseNullableField<number>(data, MC_Device_Manual_License.FIELD_MONTHS, 0);
    }

    public getEndDate(): Date | null {
        return MC_Device_Manual_License.calculateEndDate(this.start, this.months);
    }

    public static calculateEndDate(start: Date | null, months: number | null): Date | null {
        if (start != null && months != null && !isNaN(months)) {
            // Add months to the start date
            const endDate: Date = new Date(start.getTime());
            endDate.setMonth(start.getMonth() + months);
            return endDate;
        }
        return null;
    }

    public toJSON(): any {
        const data: any = {};
        data[MC_Device_Manual_License.FIELD_START] = this.start.toJSON();
        data[MC_Device_Manual_License.FIELD_MONTHS] = this.months;
        return data;
    }

}