import {MC_Constants} from "../MC_Constants";

// Just to make it easier to sync with web client
export const SIMPLE_BILLING_TYPES_VERSION: string = "0.0.1";

export class SimpleCustomer {

    public static FIELD_ID: string = "id";
    public static FIELD_EMAIL: string = "email";
    public static FIELD_NAME: string = "name";
    public static FIELD_PHONE: string = "phone";
    public static FIELD_BALANCE: string = "balance";
    public static FIELD_METADATA: string = "metadata";

    constructor(
        public id: string,
        public email: string,
        public name: string,
        public phone: string,
        public balance: number,
        public metadata: any
    ) {}

    public static fromData(data: any): SimpleCustomer {
        return new SimpleCustomer(
            data[SimpleCustomer.FIELD_ID],
            data[SimpleCustomer.FIELD_EMAIL],
            data[SimpleCustomer.FIELD_NAME],
            data[SimpleCustomer.FIELD_PHONE],
            data[SimpleCustomer.FIELD_BALANCE],
            data[SimpleCustomer.FIELD_METADATA]
        );
    }

    public toJSON(): any {
        const obj: any = {};
        obj[SimpleCustomer.FIELD_ID] = this.id;
        obj[SimpleCustomer.FIELD_EMAIL] = this.email;
        obj[SimpleCustomer.FIELD_NAME] = this.name;
        obj[SimpleCustomer.FIELD_PHONE] = this.phone;
        obj[SimpleCustomer.FIELD_BALANCE] = this.balance;
        obj[SimpleCustomer.FIELD_METADATA] = this.metadata;
        return obj;
    }

}

// Simplified Stripe Subscription
export type SimpleSubscriptionStatus =
    "incomplete"
    | "incomplete_expired"
    | "trialing"
    | "active"
    | "paused"
    | "past_due"
    | "unpaid"
    | "canceled"
    ;
export class SimpleSubscription {

    public static FIELD_ID: string = "id";
    public static FIELD_STATUS: string = "status";
    public static FIELD_CUSTOMER_ID: string = "customer_id";
    public static FIELD_DEFAULT_PAYMENT_METHOD_ID: string = "default_payment_method_id";
    public static FIELD_CREATED: string = "created";
    public static FIELD_STARTED: string = "started";
    public static FIELD_JAS_FEE_PERCENT: string = "jas_fee_percent"; // Usually irrelevant as most JAS fees are set later
    public static FIELD_CURRENT_PERIOD_START: string = "current_period_start";
    public static FIELD_CURRENT_PERIOD_END: string = "current_period_end";
    public static FIELD_CANCEL_AT_PERIOD_END: string = "cancel_at_period_end";
    public static FIELD_CANCELED_AT: string = "canceled_at";
    public static FIELD_PENDING_UPDATE: string = "pending_update";
    public static FIELD_LATEST_INVOICE: string = "latest_invoice";
    public static FIELD_ITEMS: string = "items";
    public static FIELD_METADATA: string = "metadata";

    constructor(
        public id: string,
        public status: SimpleSubscriptionStatus,
        public customerID: string,
        public defaultPaymentMethodID: string | null,
        public created: number,
        public started: number,
        public jasFeePercent: number | null,
        public currentPeriodStart: number,
        public currentPeriodEnd: number,
        public cancelAtPeriodEnd: boolean,
        public canceledAt: number | null,
        public pendingUpdate: any | null,
        public latestInvoice: SimpleInvoice | null,
        public items: SimpleSubscriptionItem[],
        public metadata: any
    ) {}

    public static fromData(data: any): SimpleSubscription {
        let simpleInvoiceData = data[this.FIELD_LATEST_INVOICE];
        let simpleInvoice: SimpleInvoice | null = null;
        if (simpleInvoiceData != null) {
            simpleInvoice = SimpleInvoice.fromData(simpleInvoiceData);
        }
        let subItems: SimpleSubscriptionItem[] = [];
        let subItemsData: any = data[SimpleSubscription.FIELD_ITEMS];
        if (subItemsData != null) {
            subItems = (subItemsData as any[]).map((x) => SimpleSubscriptionItem.fromData(x));
        }
        return new SimpleSubscription(
            data[SimpleSubscription.FIELD_ID],
            data[SimpleSubscription.FIELD_STATUS],
            data[SimpleSubscription.FIELD_CUSTOMER_ID],
            data[SimpleSubscription.FIELD_DEFAULT_PAYMENT_METHOD_ID],
            data[SimpleSubscription.FIELD_CREATED],
            data[SimpleSubscription.FIELD_STARTED],
            data[SimpleSubscription.FIELD_JAS_FEE_PERCENT],
            data[SimpleSubscription.FIELD_CURRENT_PERIOD_START],
            data[SimpleSubscription.FIELD_CURRENT_PERIOD_END],
            data[SimpleSubscription.FIELD_CANCEL_AT_PERIOD_END],
            data[SimpleSubscription.FIELD_CANCELED_AT],
            data[SimpleSubscription.FIELD_PENDING_UPDATE],
            simpleInvoice,
            subItems,
            data[SimpleSubscription.FIELD_METADATA]
        );
    }

    public toJSON(): any {
        const obj: any = {};
        obj[SimpleSubscription.FIELD_ID] = this.id;
        obj[SimpleSubscription.FIELD_STATUS] = this.status;
        obj[SimpleSubscription.FIELD_CUSTOMER_ID] = this.customerID;
        obj[SimpleSubscription.FIELD_DEFAULT_PAYMENT_METHOD_ID] = this.defaultPaymentMethodID;
        obj[SimpleSubscription.FIELD_CREATED] = this.created;
        obj[SimpleSubscription.FIELD_STARTED] = this.started;
        obj[SimpleSubscription.FIELD_JAS_FEE_PERCENT] = this.jasFeePercent;
        obj[SimpleSubscription.FIELD_CURRENT_PERIOD_START] = this.currentPeriodStart;
        obj[SimpleSubscription.FIELD_CURRENT_PERIOD_END] = this.currentPeriodEnd;
        obj[SimpleSubscription.FIELD_CANCEL_AT_PERIOD_END] = this.cancelAtPeriodEnd;
        obj[SimpleSubscription.FIELD_CANCELED_AT] = this.canceledAt;
        obj[SimpleSubscription.FIELD_PENDING_UPDATE] = this.pendingUpdate;
        obj[SimpleSubscription.FIELD_ITEMS] = this.items.map((x) => x.toJSON());
        obj[SimpleSubscription.FIELD_LATEST_INVOICE] = (this.latestInvoice != null)
            ? this.latestInvoice.toJSON()
            : null
        ;
        obj[SimpleSubscription.FIELD_METADATA] = this.metadata;
        return obj;
    }

    public static isStatusLicensed(sub: SimpleSubscription | null): boolean {
        if (sub == null) {
            return false;
        }
        // Check status
        if (sub.status === "active" || sub.status === "trialing") {
            return true;
        }
        // All other statuses are not licensed
        return false;
    }

    public static isStatusReadyForNewSub(sub: SimpleSubscription | null): boolean {
        // Doesn't have a sub yet, means ready for a new one
        if (sub == null) {
            return true;
        }
        // Check for existing subs that are invalid and non-recoverable
        if (sub.status === "incomplete_expired" || sub.status === "canceled") {
            return true;
        }
        // All other cases, it is not ready for one
        return false;
    }

}

// Simplified Stripe subscription item
export class SimpleSubscriptionItem {

    public static FIELD_ID: string = "id";
    public static FIELD_QUANTITY: string = "quantity";
    public static FIELD_METADATA: string = "metadata";
    public static FIELD_PRICE_ID: string = "price_id";
    public static FIELD_PRICE_PRODUCT_ID: string = "price_product_id";
    public static FIELD_PRICE_UNIT_AMOUNT: string = "price_unit_amount";
    public static FIELD_PRICE_METADATA: string = "price_metadata";

    constructor(
        public id: string,
        public quantity: number | null,
        public metadata: any,
        public priceID: string,
        public priceProductID: string,
        public priceUnitAmount: number | null,
        public priceMetadata: any
    ) {}

    public static fromData(data: any): SimpleSubscriptionItem {
        return new SimpleSubscriptionItem(
            data[this.FIELD_ID],
            data[this.FIELD_QUANTITY],
            data[this.FIELD_METADATA],
            data[this.FIELD_PRICE_ID],
            data[this.FIELD_PRICE_PRODUCT_ID],
            data[this.FIELD_PRICE_UNIT_AMOUNT],
            data[this.FIELD_PRICE_METADATA]
        );
    }

    public toJSON(): any {
        const obj: any = {};
        obj[SimpleSubscriptionItem.FIELD_ID] = this.id;
        obj[SimpleSubscriptionItem.FIELD_QUANTITY] = this.quantity;
        obj[SimpleSubscriptionItem.FIELD_METADATA] = this.metadata;
        obj[SimpleSubscriptionItem.FIELD_PRICE_ID] = this.priceID;
        obj[SimpleSubscriptionItem.FIELD_PRICE_PRODUCT_ID] = this.priceProductID;
        obj[SimpleSubscriptionItem.FIELD_PRICE_UNIT_AMOUNT] = this.priceUnitAmount;
        obj[SimpleSubscriptionItem.FIELD_PRICE_METADATA] = this.priceMetadata;
        return obj;
    }

}

export type SimpleInvoiceStatus = "draft" | "open" | "void" | "paid" | "uncollectible";

// Simplified Stripe invoice
export class SimpleInvoice {

    public static FIELD_ID: string = "id";
    public static FIELD_CUSTOMER_ID: string = "customer_id";
    public static FIELD_SUBSCRIPTION_ID: string = "subscription_id";
    public static FIELD_PAYMENT_INTENT_ID: string = "payment_intent_id";
    public static FIELD_CHARGE_ID: string = "charge_id";
    public static FIELD_STATUS: string = "status";
    public static FIELD_PERIOD_START: string = "period_start";
    public static FIELD_PERIOD_END: string = "period_end";
    public static FIELD_SUBTOTAL: string = "subtotal";
    public static FIELD_TAX: string = "tax";
    public static FIELD_TOTAL: string = "total";
    public static FIELD_JAS_FEE: string = "jas_fee";
    public static FIELD_AMOUNT_DUE: string = "amount_due";
    public static FIELD_AMOUNT_PAID: string = "amount_paid";
    public static FIELD_AMOUNT_REMAINING: string = "amount_remaining";
    public static FIELD_ATTEMPTED: string = "attempted";
    public static FIELD_PAID: string = "paid";
    public static FIELD_STARTING_BALANCE: string = "starting_balance";
    public static FIELD_ENDING_BALANCE: string = "ending_balance";
    public static FIELD_CREATED: string = "created";
    public static FIELD_INVOICE_PDF_URL: string = "pdf_url";
    public static FIELD_LINE_ITEMS: string = "line_items";
    public static FIELD_METADATA: string = "metadata";

    constructor(
        public id: string,
        public customerID: string | null,
        public subscriptionID: string | null,
        public paymentIntentID: string | null,
        public chargeID: string | null,
        public status: SimpleInvoiceStatus | null,
        public periodStart: number,
        public periodEnd: number,
        public subtotal: number,
        public tax: number | null,
        public total: number,
        public jasFee: number | null,
        public amountDue: number,
        public amountPaid: number,
        public amountRemaining: number,
        public attempted: boolean,
        public paid: boolean,
        public startingBalance: number,
        public endingBalance: number | null,
        public created: number,
        public invoicePDFURL: string | null,
        public lineItems: SimpleInvoiceLineItem[],
        public metadata: any | null
    ) {}

    public static fromData(data: any): SimpleInvoice {
        let lineItems: SimpleInvoiceLineItem[] = [];
        if (data[this.FIELD_LINE_ITEMS] != null) {
            lineItems = (data[this.FIELD_LINE_ITEMS] as any[]).map((x) => SimpleInvoiceLineItem.fromData(x));
        }
        return new SimpleInvoice(
            data[this.FIELD_ID],
            data[this.FIELD_CUSTOMER_ID],
            data[this.FIELD_SUBSCRIPTION_ID],
            data[this.FIELD_PAYMENT_INTENT_ID],
            data[this.FIELD_CHARGE_ID],
            data[this.FIELD_STATUS],
            data[this.FIELD_PERIOD_START],
            data[this.FIELD_PERIOD_END],
            data[this.FIELD_SUBTOTAL],
            data[this.FIELD_TAX],
            data[this.FIELD_TOTAL],
            data[this.FIELD_JAS_FEE],
            data[this.FIELD_AMOUNT_DUE],
            data[this.FIELD_AMOUNT_PAID],
            data[this.FIELD_AMOUNT_REMAINING],
            data[this.FIELD_ATTEMPTED],
            data[this.FIELD_PAID],
            data[this.FIELD_STARTING_BALANCE],
            data[this.FIELD_ENDING_BALANCE],
            data[this.FIELD_CREATED],
            data[this.FIELD_INVOICE_PDF_URL],
            lineItems,
            data[this.FIELD_METADATA]
        );
    }

    public toJSON(): any {
        const obj: any = {};
        obj[SimpleInvoice.FIELD_ID] = this.id;
        obj[SimpleInvoice.FIELD_CUSTOMER_ID] = this.customerID;
        obj[SimpleInvoice.FIELD_SUBSCRIPTION_ID] = this.subscriptionID;
        obj[SimpleInvoice.FIELD_PAYMENT_INTENT_ID] = this.paymentIntentID;
        obj[SimpleInvoice.FIELD_CHARGE_ID] = this.chargeID;
        obj[SimpleInvoice.FIELD_STATUS] = this.status;
        obj[SimpleInvoice.FIELD_PERIOD_START] = this.periodStart;
        obj[SimpleInvoice.FIELD_PERIOD_END] = this.periodEnd;
        obj[SimpleInvoice.FIELD_SUBTOTAL] = this.subtotal;
        obj[SimpleInvoice.FIELD_TAX] = this.tax;
        obj[SimpleInvoice.FIELD_TOTAL] = this.total;
        obj[SimpleInvoice.FIELD_JAS_FEE] = this.jasFee;
        obj[SimpleInvoice.FIELD_AMOUNT_DUE] = this.amountDue;
        obj[SimpleInvoice.FIELD_AMOUNT_PAID] = this.amountPaid;
        obj[SimpleInvoice.FIELD_AMOUNT_REMAINING] = this.amountRemaining;
        obj[SimpleInvoice.FIELD_ATTEMPTED] = this.attempted;
        obj[SimpleInvoice.FIELD_PAID] = this.paid;
        obj[SimpleInvoice.FIELD_STARTING_BALANCE] = this.startingBalance;
        obj[SimpleInvoice.FIELD_ENDING_BALANCE] = this.endingBalance;
        obj[SimpleInvoice.FIELD_CREATED] = this.created;
        obj[SimpleInvoice.FIELD_INVOICE_PDF_URL] = this.invoicePDFURL;
        obj[SimpleInvoice.FIELD_LINE_ITEMS] = this.lineItems.map((x) => x.toJSON());
        obj[SimpleInvoice.FIELD_METADATA] = this.metadata;
        return obj;
    }
}

// Simplified Stripe invoice item
export class SimpleInvoiceItem {

    public static FIELD_ID: string = "id";
    public static FIELD_CUSTOMER_ID: string = "customer_id";
    public static FIELD_SUBSCRIPTION_ID: string = "subscription_id";
    public static FIELD_SUBSCRIPTION_ITEM_ID: string = "subscription_item_id";
    public static FIELD_INVOICE_ID: string = "invoice_id";
    public static FIELD_CREATED_UNIX: string = "created_unix";
    public static FIELD_PRORATION: string = "proration";
    public static FIELD_UNIT_AMOUNT: string = "unit_amount";
    public static FIELD_QUANTITY: string = "quantity";
    public static FIELD_AMOUNT: string = "amount";
    public static FIELD_DESC: string = "desc";
    public static FIELD_METADATA: string = "metadata";

    constructor(
        public id: string,
        public customerID: string,
        public subscriptionID: string | null,
        public subscriptionItemID: string | null,
        public invoiceID: string | null,
        public createdUnix: number,
        public proration: boolean,
        public unitAmount: number | null,
        public quantity: number,
        public amount: number,
        public desc: string | null,
        public metadata: any | null
    ) {}

    public static fromData(data: any): SimpleInvoiceItem {
        return new SimpleInvoiceItem(
            data[this.FIELD_ID],
            data[this.FIELD_CUSTOMER_ID],
            data[this.FIELD_SUBSCRIPTION_ID],
            data[this.FIELD_SUBSCRIPTION_ITEM_ID],
            data[this.FIELD_INVOICE_ID],
            data[this.FIELD_CREATED_UNIX],
            data[this.FIELD_PRORATION],
            data[this.FIELD_UNIT_AMOUNT],
            data[this.FIELD_QUANTITY],
            data[this.FIELD_AMOUNT],
            data[this.FIELD_DESC],
            data[this.FIELD_METADATA]
        );
    }

    public toJSON(): any {
        const data: any = {};
        data[SimpleInvoiceItem.FIELD_ID] = this.id;
        data[SimpleInvoiceItem.FIELD_CUSTOMER_ID] = this.customerID;
        data[SimpleInvoiceItem.FIELD_SUBSCRIPTION_ID] = this.subscriptionID;
        data[SimpleInvoiceItem.FIELD_SUBSCRIPTION_ITEM_ID] = this.subscriptionItemID;
        data[SimpleInvoiceItem.FIELD_INVOICE_ID] = this.invoiceID;
        data[SimpleInvoiceItem.FIELD_CREATED_UNIX] = this.createdUnix;
        data[SimpleInvoiceItem.FIELD_PRORATION] = this.proration;
        data[SimpleInvoiceItem.FIELD_UNIT_AMOUNT] = this.unitAmount;
        data[SimpleInvoiceItem.FIELD_QUANTITY] = this.quantity;
        data[SimpleInvoiceItem.FIELD_AMOUNT] = this.amount;
        data[SimpleInvoiceItem.FIELD_DESC] = this.desc;
        data[SimpleInvoiceItem.FIELD_METADATA] = this.metadata;
        return data;
    }

}

// Simplified Stripe invoice (line) item
export class SimpleInvoiceLineItem {

    public static FIELD_ID: string = "id";
    public static FIELD_TYPE: string = "type";
    public static FIELD_DESC: string = "desc";
    public static FIELD_PERIOD_START: string = "period_start";
    public static FIELD_PERIOD_END: string = "period_end";
    public static FIELD_AMOUNT: string = "amount";
    public static FIELD_PRORATION: string = "proration";
    public static FIELD_QUANTITY: string = "quantity";
    public static FIELD_PRICE_ID: string = "price_id";
    public static FIELD_PRICE_UNIT_AMOUNT: string = "price_unit_amount";
    public static FIELD_INVOICE_ITEM_ID: string = "invoice_item_id";
    public static FIELD_SUBSCRIPTION_ID: string = "subscription_id";
    public static FIELD_SUBSCRIPTION_ITEM_ID: string = "subscription_item_id";
    public static FIELD_METADATA: string = "metadata";

    constructor(
        public id: string,
        public type: "invoiceitem" | "subscription",
        public desc: string | null,
        public periodStart: number,
        public periodEnd: number,
        public amount: number | null,
        public proration: boolean,
        public quantity: number | null,
        public priceID: string | null,
        public priceUnitAmount: number | null,
        public invoiceItemID: string | null,
        public subscriptionID: string | null,
        public subscriptionItemID: string | null,
        public metadata: any | null
    ) {}

    public static fromData(data: any): SimpleInvoiceLineItem {
        return new SimpleInvoiceLineItem(
            data[this.FIELD_ID],
            data[this.FIELD_TYPE],
            data[this.FIELD_DESC],
            data[this.FIELD_PERIOD_START],
            data[this.FIELD_PERIOD_END],
            data[this.FIELD_AMOUNT],
            data[this.FIELD_PRORATION],
            data[this.FIELD_QUANTITY],
            data[this.FIELD_PRICE_ID],
            data[this.FIELD_PRICE_UNIT_AMOUNT],
            data[this.FIELD_INVOICE_ITEM_ID],
            data[this.FIELD_SUBSCRIPTION_ID],
            data[this.FIELD_SUBSCRIPTION_ITEM_ID],
            data[this.FIELD_METADATA]
        );
    }

    public toJSON(): any {
        const obj: any = {};
        obj[SimpleInvoiceLineItem.FIELD_ID] = this.id;
        obj[SimpleInvoiceLineItem.FIELD_TYPE] = this.type;
        obj[SimpleInvoiceLineItem.FIELD_DESC] = this.desc;
        obj[SimpleInvoiceLineItem.FIELD_PERIOD_START] = this.periodStart;
        obj[SimpleInvoiceLineItem.FIELD_PERIOD_END] = this.periodEnd;
        obj[SimpleInvoiceLineItem.FIELD_AMOUNT] = this.amount;
        obj[SimpleInvoiceLineItem.FIELD_PRORATION] = this.proration;
        obj[SimpleInvoiceLineItem.FIELD_QUANTITY] = this.quantity;
        obj[SimpleInvoiceLineItem.FIELD_PRICE_ID] = this.priceID;
        obj[SimpleInvoiceLineItem.FIELD_PRICE_UNIT_AMOUNT] = this.priceUnitAmount;
        obj[SimpleInvoiceLineItem.FIELD_INVOICE_ITEM_ID] = this.invoiceItemID;
        obj[SimpleInvoiceLineItem.FIELD_SUBSCRIPTION_ID] = this.subscriptionID;
        obj[SimpleInvoiceLineItem.FIELD_SUBSCRIPTION_ITEM_ID] = this.subscriptionItemID;
        obj[SimpleInvoiceLineItem.FIELD_METADATA] = this.metadata;
        return obj;
    }

}

// Simplified Stripe SetupIntent
export type SimpleSetupIntentStatus =
    "requires_payment_method"
    | "requires_confirmation"
    | "requires_action"
    | "processing"
    | "canceled"
    | "succeeded"
    ;
export class SimpleSetupIntent {

    // Simple version of a SetupIntent
    // https://stripe.com/docs/api/setup_intents/object

    // The client secret is NOT stored in the SimpleSetupIntent
    // It's a security risk, and the customer can load it independently via the web client
    // See https://stripe.com/docs/api/setup_intents/object#setup_intent_object-client_secret

    // We also don't store SetupIntent fields "next_action", "last_setup_error", "cancellation_reason"
    // Simply because those can be loaded from the client when needed (with a publishable key)
    // References:
    // https://stripe.com/docs/api/setup_intents/object#setup_intent_object-next_action
    // https://stripe.com/docs/api/setup_intents/object#setup_intent_object-last_setup_error
    // https://stripe.com/docs/api/setup_intents/object#setup_intent_object-cancellation_reason

    public static FIELD_ID: string = "id";
    public static FIELD_CUSTOMER_ID: string = "customer_id";
    public static FIELD_PAYMENT_METHOD_ID: string = "payment_method_id";
    public static FIELD_CREATED_UNIX: string = "created_unix";
    public static FIELD_STATUS: string = "status";
    public static FIELD_DESC: string = "desc";
    public static FIELD_METADATA: string = "metadata";

    constructor(
        public id: string,
        public customerID: string | null,
        public paymentMethodID: string | null,
        public createdUnix: number,
        public status: SimpleSetupIntentStatus,
        public desc: string | null,
        public metadata: any | null
    ) {}

    public static fromData(data: any): SimpleSetupIntent {
        // Parse variable type fields
        const customerID: string | null = MC_Constants.parseNullableField<string | null>(
            data, this.FIELD_CUSTOMER_ID, null
        );
        const pmID: string | null = MC_Constants.parseNullableField<string | null>(
            data, this.FIELD_PAYMENT_METHOD_ID, null
        );
        const desc: string | null = MC_Constants.parseNullableField<string | null>(
            data, this.FIELD_DESC, null
        );
        // This is just paranoia about undefined vs null lol
        const metadata: any = MC_Constants.parseNullableField<any | null>(
            data, this.FIELD_METADATA, null
        );
        // Create SSI
        return new SimpleSetupIntent(
            data[this.FIELD_ID] as string,
            customerID,
            pmID,
            data[this.FIELD_CREATED_UNIX] as number,
            data[this.FIELD_STATUS] as SimpleSetupIntentStatus,
            desc,
            metadata
        );
    }

    public toJSON(): any {
        const data: any = {};
        data[SimpleSetupIntent.FIELD_ID] = this.id;
        data[SimpleSetupIntent.FIELD_CUSTOMER_ID] = this.customerID;
        data[SimpleSetupIntent.FIELD_PAYMENT_METHOD_ID] = this.paymentMethodID;
        data[SimpleSetupIntent.FIELD_CREATED_UNIX] = this.createdUnix;
        data[SimpleSetupIntent.FIELD_STATUS] = this.status;
        data[SimpleSetupIntent.FIELD_DESC] = this.desc;
        data[SimpleSetupIntent.FIELD_METADATA] = this.metadata;
        return data;
    }

}

// Simplified Stripe PaymentMethod
// https://stripe.com/docs/api/payment_methods/object
export interface SimpleCardConfig {
    brand?: string;
    last4?: string;
    exp_month?: number;
    exp_year?: number;
}
export interface SimpleBillingAddress {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
    state?: string;
}
export interface SimpleBillingDetails {
    name?: string;
    email?: string;
    phone?: string;
    address?: SimpleBillingAddress;
}
export class SimplePaymentMethod {

    public static FIELD_ID: string = "id";
    public static FIELD_CUSTOMER_ID: string = "customer_id";
    public static FIELD_BILLING_DETAILS: string = "billing_details";
    public static FIELD_TYPE: string = "type";
    public static FIELD_CREATED_UNIX: string = "created_unix";
    public static FIELD_CONFIG: string = "config";
    public static FIELD_METADATA: string = "metadata";

    constructor(
        public id: string,
        public customerID: string | null,
        public billingDetails: SimpleBillingDetails | null,
        public type: string, // SHOULD ONLY BE "card" FOR NOW
        public createdUnix: number,
        public config: SimpleCardConfig | any | null, // Type-specific config
        public metadata: any | null
    ) {}

    public static fromData(data: any): SimplePaymentMethod {
        // Parse nullable fields (avoid undefined values)
        const customerID: string | null = MC_Constants.parseNullableField<string | null>(
            data, this.FIELD_CUSTOMER_ID, null
        );
        const billingDetails: SimpleBillingDetails | null = (data[this.FIELD_BILLING_DETAILS] != null)
            ? data[this.FIELD_BILLING_DETAILS] as SimpleBillingDetails
            : null
        ;
        const config: any | null = MC_Constants.parseNullableField<any | null>(data, this.FIELD_CONFIG, null);
        const metadata: any | null = MC_Constants.parseNullableField<any | null>(data, this.FIELD_METADATA, null);
        // Create SPM
        return new SimplePaymentMethod(
            data[this.FIELD_ID] as string,
            customerID,
            billingDetails,
            data[this.FIELD_TYPE] as string,
            data[this.FIELD_CREATED_UNIX] as number,
            config,
            metadata
        );
    }

    public toJSON(): any {
        const data: any = {};
        data[SimplePaymentMethod.FIELD_ID] = this.id;
        data[SimplePaymentMethod.FIELD_CUSTOMER_ID] = this.customerID;
        data[SimplePaymentMethod.FIELD_BILLING_DETAILS] = this.billingDetails;
        data[SimplePaymentMethod.FIELD_TYPE] = this.type;
        data[SimplePaymentMethod.FIELD_CREATED_UNIX] = this.createdUnix;
        data[SimplePaymentMethod.FIELD_CONFIG] = this.config;
        data[SimplePaymentMethod.FIELD_METADATA] = this.metadata;
        return data;
    }

}
