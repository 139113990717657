import {MC_Constants} from "../MC_Constants";
import {
    SimpleCustomer,
    SimpleInvoiceItem,
    SimplePaymentMethod,
    SimpleSetupIntent,
    SimpleSubscription
} from "./simple_stripe_types";

export class MC_Organization {

    public static FIELD_ID: string = "id";
    public static FIELD_KEY: string = "key";
    public static FIELD_ENABLED: string = "enabled";
    public static FIELD_CREATED: string = "created";
    public static FIELD_MODIFIED: string = "modified";
    public static FIELD_NAME: string = "name";
    public static FIELD_USER_IDS: string = "user_ids";
    public static FIELD_DEVICE_IDS: string = "device_ids";
    public static FIELD_ADMIN_NOTES: string = "admin_notes";
    public static FIELD_SIMPLE_CUSTOMER: string = "simple_stripe_customer";
    public static FIELD_SIMPLE_PENDING_INVOICE_ITEMS: string = "simple_stripe_pending_invoice_items";
    public static FIELD_SIMPLE_SETUP_INTENTS: string = "simple_stripe_setup_intents";
    public static FIELD_SIMPLE_PAYMENT_METHODS: string = "simple_stripe_payment_methods";
    public static FIELD_SIMPLE_SUBSCRIPTIONS: string = "simple_stripe_subscriptions";

    private constructor(
        public id: string,
        public key: string,
        public created: Date,
        public modified: Date,
        public enabled: boolean,
        public name: string,
        public userIDs: string[],
        public deviceIDs: string[],
        public adminNotes: string,
        public simpleCustomer: SimpleCustomer | null,
        public simplePendingInvoiceItems: SimpleInvoiceItem[],
        public simpleSetupIntents: SimpleSetupIntent[],
        public simplePaymentMethods: SimplePaymentMethod[],
        public simpleSubscriptions: SimpleSubscription[]
    ) {}

    public static fromData(data: any): MC_Organization {
        let createdDate: Date | null = MC_Constants.parseDate(data[this.FIELD_CREATED]);
        if (createdDate == null) {
            createdDate = new Date(0);
        }
        let modDate: Date | null = MC_Constants.parseDate(data[this.FIELD_MODIFIED]);
        if (modDate == null) {
            modDate = new Date(0);
        }
        const customerAny: any | null = data[this.FIELD_SIMPLE_CUSTOMER];
        const customer: SimpleCustomer | null = (customerAny != null)
            ? SimpleCustomer.fromData(customerAny)
            : null
        ;
        const pii: SimpleInvoiceItem[] = (data[this.FIELD_SIMPLE_PENDING_INVOICE_ITEMS] as any[])
            .map((x) => SimpleInvoiceItem.fromData(x))
        ;
        const ssi: SimpleSetupIntent[] = (data[this.FIELD_SIMPLE_SETUP_INTENTS] as any[])
            .map((x) => SimpleSetupIntent.fromData(x))
        ;
        const pms: SimplePaymentMethod[] = (data[this.FIELD_SIMPLE_PAYMENT_METHODS] as any[])
            .map((x) => SimplePaymentMethod.fromData(x))
        ;
        const subs: SimpleSubscription[] = (data[this.FIELD_SIMPLE_SUBSCRIPTIONS] as any[])
            .map((x) => SimpleSubscription.fromData(x))
        ;
        return new MC_Organization(
            data[this.FIELD_ID] as string,
            data[this.FIELD_KEY] as string,
            createdDate,
            modDate,
            data[this.FIELD_ENABLED] as boolean,
            data[this.FIELD_NAME] as string,
            data[this.FIELD_USER_IDS] as string[],
            data[this.FIELD_DEVICE_IDS] as string[],
            data[this.FIELD_ADMIN_NOTES] as string,
            customer,
            pii,
            ssi,
            pms,
            subs
        );
    }

    public static fromArrayData(dataArray: any): MC_Organization[] {
        let orgs: MC_Organization[] = [];
        for (let i = 0; i < dataArray.length; i++) {
            try {
                orgs[orgs.length] = this.fromData(dataArray[i]);
            } catch (e) {
                console.error("Err parsing org: " + e + ": " + JSON.stringify(dataArray[i]));
            }
        }
        return orgs;
    }

}
